.mobileModal {
  background: none !important;
}

.mobileModal .header,
.mobileModal .content,
.mobileModal .actions {
  background-color: var(--main-panel-color) !important;
}

.mobileModal .header {
  color: white !important;
}
