.chrome-picker {
  width: auto !important;
}

.color-cell-container {
  overflow: auto;
  /*
  height: 30vh;
  */
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 5px;
  padding: 5px;
}

.color-cell {
  width: 2em;
  height: 2em;
  margin: 3px;
  border-radius: 2px;
  border-width: 3px;
  border-color: black;
  border-style: solid;
  cursor: pointer;
}

.color-cell.active {
  box-shadow: 0 0 0 3px white;
}

.add-cell-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-color: white;
  border-width: 3px;
  color: white;
}

.add-cell-btn > i {
  margin: 0;
  height: auto;
}

/* Only hover for non-touch devices */
@media (hover: hover) {
  .add-cell-btn:hover {
    color: var(--hover-color);
    border-color: var(--hover-color);
  }
}
